/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql, withPrefix, Link } from "gatsby"
import "./layout.css"

import { component as Alert, fixtures as AlertFixtures } from "./alert"
import Contact from "./contact"
import Footer from "./footer"
import Image from "./image"

export default class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showHeader:
        this.props.header === undefined || this.props.header === true
          ? true
          : false,
    }
  }

  headerMenuLinks(data) {
    if (!data) return null
    return data.properties.map((link, idx) => (
      <li id={link.id} className={link.className} key={idx}>
        <Link to={link.href} onClick={this.onClick} activeClassName="current">
          <div>{link.title}</div>
        </Link>
      </li>
    ))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            footerLogos: graphCmsBlock(title: { eq: "FOOTER_LOGO" }) {
              image {
                url
              }
            }
            footerTexts: graphCmsBlock(title: { eq: "FOOTER_TEXT" }) {
              body
            }
            headerLogos: graphCmsBlock(title: { eq: "HEADER_LOGO" }) {
              image {
                url
              }
            }
            headerMenus: graphCmsBlock(title: { eq: "HEADER_MENU" }) {
              properties
            }
          }
        `}
        render={data => {
          return (
            <>
              <Helmet
                bodyAttributes={{
                  class: "stretched",
                }}
                onChangeClientState={() =>
                  window.dispatchEvent(new Event("resize"))
                }
              >
                <script src={withPrefix("/javascripts/functions.js")} />
              </Helmet>
              <div id="wrapper" className="clearfix">
                {this.state.showHeader && (
                  <header
                    id="header"
                    className="full-header"
                    data-sticky-class="not-dark"
                  >
                    <div id="header-wrap">
                      <div className="container clearfix">
                        <div className="flex-menu-group">
                          <div id="primary-menu-trigger">
                            <i className="icon-reorder" />
                          </div>
                          <div id="logo">
                            <a href="/" onClick={this.onClick}>
                              <Image src={data.headerLogos.image.url} />
                            </a>
                          </div>
                        </div>
                        <nav id="primary-menu" className="dark style-3">
                          <ul>{this.headerMenuLinks(data.headerMenus)}</ul>
                        </nav>
                      </div>
                    </div>
                  </header>
                )}
                {this.props.children}
              </div>

              {/* <div id="gotoTop" className="icon-angle-up" /> */}

              {this.props.footerContact && (
                <Contact {...this.props.footerContact} />
              )}
              {!this.props.hideAlert && (
                <Alert theme="modal-bottom-right" {...AlertFixtures} />
              )}
              <Footer
                logo={data.footerLogos.image.url}
                text={data.footerTexts.body}
              />
            </>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  footerContact: null,
}
