import { useState, useEffect } from "react"

const useScreenSize = () => {
  const isBrowser = () => typeof window !== "undefined"

  const [screenSize, setScreenSize] = useState({
    width: isBrowser() && window.innerWidth,
    height: isBrowser() && window.innerHeight,
  })

  useEffect(() => {
    if (isBrowser()) {
      const handleResize = () => {
        setScreenSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }

      window.addEventListener("resize", handleResize)

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  return screenSize
}

export default useScreenSize
