import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown"

export default class contact extends Component {
  onClick() {
    // ga('send', 'event', 'Homepage', 'Lets Work Together', 'Contact Us');
  }

  render() {
    if (!this.props.heading && !this.props.body) return null

    return (
      <StaticQuery
        query={graphql`
          query {
            callout1: file(relativePath: { eq: "8451/contact_us.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 4160) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => {
          var styles = {
            backgroundImage: `url("${
              data.callout1.childImageSharp.fluid.src
            }")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }

          return (
            <section
              id="contact"
              className="parallax"
              data-stellar-background-ratio="0.4"
              style={styles}
            >
              <div className="container clearfix center">
                {this.props.heading && (
                  <h1 className="white">{this.props.heading}</h1>
                )}
                {this.props.body && <Markdown source={this.props.body} />}
                <a
                  href="/contact-us.html"
                  className="button button-border button-light button-rounded button-large noleftmargin keyline-button-hover"
                  onClick={this.onClick()}
                >
                  Contact us
                </a>
              </div>
            </section>
          )
        }}
      />
    )
  }
}

contact.defaultProps = {
  heading: null,
  body: null,
}
