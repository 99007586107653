import React, { useEffect, useState } from "react"
import Image from "./image"
import { getUserLocation } from "../lib/utils"
import { useQueryParamString } from "react-use-query-param-string"

import tagFraudBadge from "../images/TAG-certified-against-fraud.png"
import tagMalwareBadge from "../images/TAG-certified-against-malware.png"
import tagPiracyBadge from "../images/TAG-certified-against-piracy.png"
import tagIQGBadge from "../images/TAG-IQG-certified.png"

const Footer = ({ logo, text }) => {
  const [locale, setLocale] = useState(null)
  const [debug] = useQueryParamString("debug", undefined)

  useEffect(() => {
    getUserLocation().then(location => {
      setLocale(location)
    })
  }, [])

  const isTodayAfterDate = dateString => {
    const today = new Date()
    const date = new Date(dateString)
    return today > date
  }

  const targetedLocales = () => {
    let locales = ["California", "Colorado", "Connecticut", "Utah"]
    if (isTodayAfterDate("2024-03-27") || debug) {
      locales = [...locales, "Washington", "Nevada"]
    }
    return locales
  }

  const isTargetedLocale = locale => {
    return targetedLocales().includes(locale)
  }

  const privacyPolicy = {
    url: "https://www.kroger.com/i/privacy-policy/",
    label: "Kroger Privacy Policy",
  }

  const waHealthUpdate = {
    url: "http://www.kroger.com/i/privacy-policy/health-data/wa",
    label: "WA Health Policy",
  }

  const yourPrivacyChoices = {
    url:
      "https://privacyportal.onetrust.com/webform/f95f67ef-e8ad-4274-9c69-04fd38042f86/bdcb0219-178b-4c21-845c-2c58736c7a6e",
    label: "Your Privacy Choices",
    icon: (
      <svg
        viewBox="0 0 30 14"
        style={{
          width: "50px",
          marginLeft: "5px",
          position: "relative",
        }}
      >
        <path
          d="M7.4 12.8h6.8l3.1-11.6H7.4C4.2 1.2 1.6 3.8 1.6 7s2.6 5.8 5.8 5.8z"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "#fff",
          }}
        />
        <path
          d="M22.6 0H7.4c-3.9 0-7 3.1-7 7s3.1 7 7 7h15.2c3.9 0 7-3.1 7-7s-3.2-7-7-7zm-21 7c0-3.2 2.6-5.8 5.8-5.8h9.9l-3.1 11.6H7.4c-3.2 0-5.8-2.6-5.8-5.8z"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            fill: "#06f",
          }}
        />
        <path
          d="M24.6 4c.2.2.2.6 0 .8L22.5 7l2.2 2.2c.2.2.2.6 0 .8-.2.2-.6.2-.8 0l-2.2-2.2-2.2 2.2c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L20.8 7l-2.2-2.2c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0l2.2 2.2L23.8 4c.2-.2.6-.2.8 0z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M12.7 4.1c.2.2.3.6.1.8L8.6 9.8c-.1.1-.2.2-.3.2-.2.1-.5.1-.7-.1L5.4 7.7c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0L8 8.6l3.8-4.5c.2-.2.6-.2.9 0z"
          style={{ fill: "#06f" }}
        />
      </svg>
    ),
  }

  // Function to dynamically generate links based on locale
  const generateLinks = () => {
    const links = []

    // append privacy policy to the links menu
    if (targetedLocales().includes("Nevada") && locale === "Nevada") {
      privacyPolicy.url += "#nevada"
    }
    links.push(privacyPolicy)

    // Logic for adding locale-specific links
    if (locale && isTargetedLocale(locale)) {
      if (locale === "Washington") {
        links.push(waHealthUpdate)
      }
      if (!["Washington", "Nevada"].includes(locale)) {
        links.push(yourPrivacyChoices)
      }
    }

    return links
  }

  const links = generateLinks()

  return (
    <footer id="footer">
      <div id="tagline">
        <div className="container clearfix">
          <div id="footer-logo" className="center">
            <a href="index.html" className="standard-logo">
              <Image src={logo} alt="KPM Logo" />
            </a>
          </div>
        </div>
      </div>

      <div id="copyrights">
        <div className="container clearfix">
          <div className="col_half">
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {links.map((link, index) => (
              <a
                key={index}
                href={link.url}
                style={{ display: "flex", alignItems: "center" }}
              >
                {link.label}
                {link.icon && link.icon}
              </a>
            ))}
          </div>
          <Image
            className="tag-logo"
            src={tagFraudBadge}
            alt="TAG Certified Against Fraud"
          />
          <Image
            className="tag-logo"
            src={tagMalwareBadge}
            alt="TAG Certified Against Malware"
          />
          <Image
            className="tag-logo"
            src={tagPiracyBadge}
            alt="TAG Certified Against Piracy"
          />
          <Image
            className="tag-logo"
            src={tagIQGBadge}
            alt="TAG IQG Certified"
          />
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  logo: null,
  text: null,
}

export default Footer
