import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { StaticImage } from "gatsby-plugin-image"
import IframeResizer from "iframe-resizer-react"
import useScreenSize from "../../hooks/useScreenSize"

import SVG from "../svg"

import {
  body,
  button,
  close,
  collapsed,
  content,
  iframe,
  iframe_header,
  iframe_wrapper,
  image,
  is_collapsed,
  is_open,
  modal_iframe,
  modal_inner,
  modal,
  theme_bar,
  theme_modal_center,
  theme_modal_bottom_left,
  theme_modal_bottom_right,
} from "./styles.module.scss"

const themeOptions = {
  "bar-top": theme_bar,
  "modal-center": theme_modal_center,
  "modal-bottom-left": theme_modal_bottom_left,
  "modal-bottom-right": theme_modal_bottom_right,
}

const Alert = props => {
  let { body: $body, button: $button, theme, title: $title, openLabel } = props
  const iframeRef = useRef(null)
  const hasIframe = props.src !== undefined
  const screenSize = useScreenSize()

  const $modal = theme !== "bar-top"
  // const $modalCentered = theme === "modal-center"
  const $bodyContent = $body || $title

  let [modalIsOpen, setModalIsOpen] = useState($modal && true)

  const classes = classNames(content, {
    [themeOptions[theme]]: !$modal && themeOptions[theme],
  })

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
    lockScroll(!modalIsOpen && !hasIframe)
  }

  const lockScroll = isOpen => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.removeProperty("overflow")
    }
  }

  const imageTag = (
    <div className={image}>
      <StaticImage
        alt="Kroger Private Marketplace"
        layout="constrained"
        placeholder="blurred"
        quality={100}
        src="../../images/PMP-Image-for-Website-cropped.jpg"
        width={160}
      />
    </div>
  )

  let alertContents

  if (hasIframe) {
    alertContents = (
      <div className={classes}>
        <div className={body}>
          {openLabel && modalIsOpen && (
            <div className={iframe_header} onClick={toggleModal}>
              <h3>{openLabel}</h3>
            </div>
          )}
          {openLabel && !modalIsOpen && (
            <div className={iframe_header} onClick={toggleModal}>
              <h3>{openLabel}</h3>
            </div>
          )}
          <div className={iframe_wrapper}>
            {screenSize.width < 481 && (
              <iframe
                src={props.src}
                className={iframe}
                style={{ padding: "20px" }}
              />
            )}
            {screenSize.width > 480 && (
              <IframeResizer forwardRef={iframeRef} src={props.src} />
            )}
          </div>
        </div>
      </div>
    )
  } else {
    alertContents = (
      <div className={classes}>
        <div>
          {$modal && imageTag}

          {$bodyContent && (
            <div className={body}>
              {$title && <strong>{$title}</strong>}
              {$body && <p>{$body}</p>}
            </div>
          )}

          {!$modal && imageTag}

          {$button && (
            <div className={button}>
              <a
                className="button button-border button-light button-rounded button-large keyline-button-hover"
                href={$button.url}
                rel="noreferrer"
                target="_blank"
              >
                {$button.label}
              </a>
            </div>
          )}
        </div>
      </div>
    )
  }

  let alertComponent = alertContents

  if ($modal) {
    const modalClasses = classNames(modal, {
      [themeOptions[theme]]: themeOptions[theme],
      [is_open]: modalIsOpen,
      [is_collapsed]: !modalIsOpen && hasIframe,
      [modal_iframe]: hasIframe,
    })

    const handleClass = classNames(close, {
      [collapsed]: !modalIsOpen && hasIframe,
    })

    alertComponent = (
      <div className={modalClasses}>
        <div className={modal_inner}>
          <button
            className={handleClass}
            type="button"
            aria-label="Close"
            onClick={toggleModal}
          >
            <SVG name={hasIframe ? "expand" : "close"} />
          </button>
          {alertContents}
        </div>
      </div>
    )
  }

  return alertComponent
}

Alert.propTypes = {
  /**
   * Specifies the body
   */
  body: PropTypes.string,

  /**
   * Specifies the label and url of the Button.
   */
  button: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),

  /**
   * Specifies the alert's theme
   */

  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the title
   */
  title: PropTypes.string,

  /**
   * Specifies the src of an embedded iframe
   */
  src: PropTypes.string,

  /**
   * Specifies the value of the open button
   */
  openLabel: PropTypes.string,
}

Alert.defaultProps = {}

export default Alert
