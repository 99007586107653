const cookies = require("js-cookie")

const getUserLocation = async () => {
  const key = "userLocale"
  let locale = cookies.get(key)
  if (locale === undefined) {
    const response = await fetch("https://www.8451.com/api/UserLocation", {
      method: "POST",
    })
    locale = await response.text()
    cookies.set(key, locale, { expires: 1 })
  }
  return locale
}

/**
 * Determines if today's date is greater than a specified date.
 * The comparison respects the user's timezone.
 * @param {String} oldDate The date to compare against today's date.
 * @returns {boolean} True if today's date is greater than the specified date; otherwise, false.
 */
const isTodayAfterDate = oldDate => {
  const now = new Date()
  const then = new Date(oldDate)

  // Convert both dates to UTC to compare them fairly
  const nowUTC = new Date(now.toUTCString())
  const thenUTC = new Date(then.toUTCString())

  return nowUTC > thenUTC
}

module.exports = {
  getUserLocation,
  isTodayAfterDate,
}
