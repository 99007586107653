/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from "react"
import Img from "gatsby-image"

export default class Image extends Component {
  render() {
    if (this.props.fluid || this.props.fixed) return <Img {...this.props} />
    return <img {...this.props} />
  }
}

Image.defaultProps = {
  fixed: null,
  fluid: null,
  src: null,
}
